<template>
  <v-app :style="fontStyle">
    <!-- progress loader -->
    <v-progress-linear
      :active="isLoaderVisible"
      :indeterminate="true"
      absolute
      top
      color="primary"
    ></v-progress-linear>

    <v-main class="accent">
      <div class="pa-3 px-md-9 py-pd-6 min-height-100">
        <router-view />
      </div>

      <toast-messages />
    </v-main>
  </v-app>
</template>

<script>
const ToastMessages = () => import(/* webpackChunkName: "toast-messages" */ "@/components/common/ToastMessages.vue")

export default {
  name: "MinimalTemplate",

  components: {
    ToastMessages
  },

  computed: {
    // find whether to show the loader or not from vuex store
    isLoaderVisible() {
      return this.$store.getters["loaders/isVisible"]
    }
  }
}
</script>
